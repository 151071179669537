import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const isCurrentRouteWallet = (
  currentRoute: RouteLocationNormalizedLoaded,
) => {
  return currentRoute.path.startsWith("/painel/wallet");
};

export const isCurrentRouteProfile = (
  currentRoute: RouteLocationNormalizedLoaded,
) => {
  return currentRoute.path.startsWith("/painel/perfil");
};

export const isCurrentRouteHelp = (
  currentRoute: RouteLocationNormalizedLoaded,
) => {
  return currentRoute.path.startsWith("/painel/ajuda");
};

import { isCurrentRouteWallet } from "./identifyRoute";
import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const getBreadcrumbsIcon = (
  currentRoute: RouteLocationNormalizedLoaded,
) => {
  if (isCurrentRouteWallet(currentRoute)) {
    return "/breadcrumbs/wallet.svg";
  }

  return "/breadcrumbs/profile.svg";
};

import type { RouteLocationMatch } from "../protocols";

export const useGetCurrentRouteRecords = () => {
  const currentRoute = useRoute();
  const router = useRouter();

  return (): RouteLocationMatch[] => {
    const routeSegments = currentRoute.path.split("/").filter(Boolean);
    const routeRecords = routeSegments
      .map((_, index) => {
        const path = "/" + routeSegments.slice(0, index + 1).join("/");
        const matchingRoute = router.resolve(path);
        return matchingRoute;
      })
      .filter((record) => record?.name);

    return routeRecords;
  };
};
